import Vue from 'vue'
import { baseAPIURL } from '../APILinks'

const timeout = 10000
export default {
  // get all packages
  async getPackages () {
    try {
      return await Vue.axios.get(baseAPIURL + 'package',
        {
          timeout: timeout,
          headers: {
            'Content-Type': 'application/json'
          }
        })
    } catch (err) {
      console.log('error', err.message)
    }
  },

  // get one package details
  async getPackage (id) {
    try {
      return await Vue.axios.get(baseAPIURL + 'package/' + id,
        {
          timeout: timeout,
          headers: {
            'Content-Type': 'application/json'
          }
        })
    } catch (err) {
      console.log('error', err.message)
    }
  },

  // check Package Price
  async checkPackagePrice (body, id) {
    try {
      return await Vue.axios.post(baseAPIURL + 'package/check_availability/' + id,
        body,
        {
          timeout: 20000,
          headers: {
            'Content-Type': 'application/json'
          }
        })
    } catch (err) {
      console.log('error', err.message)
    }
  },

  // save package
  async savePackage (body) {
    try {
      return await Vue.axios.post(baseAPIURL + 'package/save',
        body,
        {
          timeout: 20000,
          headers: {
            'Content-Type': 'application/json'
          }
        })
    } catch (err) {
      console.log('error', err.message)
    }
  }
}
