<template>
    <div>
        <div v-for="(item) in packageDetails.cities" :key="item.cityID" class="my-5">
            <v-card outlined  class="pa-5" v-if="item.cityHotels[0]">
                <p class="headline"><v-icon color="primary lighten-2" class="mb-1">mdi-map-marker</v-icon> {{item.cityName}}</p>
                <v-row justify="space-between" class="px-5">
                    <h3 class="text-h6 headers--text text-capitalize">
                        <v-icon color="primary lighten-2" class="mb-1">mdi-bed</v-icon>
                        {{item.cityHotels[0].hotelName}}
                        <!-- {{item.cityHotels[0].hotelName}} -->
                    </h3>
                    <div class="d-flex align-center">
                        <v-btn :disabled="item.cityNightNumber >= 9" small elevation="0" tile color="grey lighten-2" class="pa-5" @click="changeHotelsNights(item.cityID, 'inc')">+</v-btn>
                        <div class="grey lighten-3 pa-2 body-1">{{item.cityNightNumber}} Nights</div>
                        <v-btn :disabled="(item.cityNightNumber <= 0 && item.cityName === 'Medina') || (item.cityNightNumber <= 2 && item.cityName === 'Mecca')" small elevation="0" tile color="grey lighten-2" class="pa-5" @click="changeHotelsNights(item.cityID, 'dec')">-</v-btn>
                    </div>
                </v-row>
                <p class="mt-3 mb-0 ml-2">Occupancy: {{adults === 1 ? 'Single' : (adults === 2 ? 'Double' : (adults === 3 ? 'Triple' : 'Quad'))}} Room</p>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
//   props: ['cities'],
  data () {
    return {
      cityMinNights: []
    }
  },
  computed: {
    ...mapState(['adults', 'packageDetails'])
  },
  methods: {
    changeHotelsNights (cityId, method) {
      this.$emit('clearFlight')
      this.$store.dispatch('changeHotelsNights', { id: cityId, action: method })
    }
  },
  created () {
    this.packageDetails.cities.forEach(element => {
      this.cityMinNights.push(element.cityNightNumber)
    })
  }
}
</script>
