import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1a3057',
        headers: '#424242'
        // secondary: colors.red.lighten4, #FFCDD2
        // accent: colors.indigo.base #3F51B5
      }
    }
  }
})
