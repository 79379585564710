<template>
  <!-- <v-container>
    <v-snackbar
    :timeout="3000"
    v-model="alertExist"
    top
    :color="alertType"
    elevation="24"
    >
      {{alertText}}
    </v-snackbar>
    <h2 class="text-h4 text-capitalize font-weight-black primary--text my-10 headline-text">choose your umrah package</h2>
    <v-row v-if="!loading" justify="center">
      <template v-if="packagesList.length">
        <v-col v-for="item in packagesList" :key="item.id" cols="12" sm="6" md="4">
          <packageCard :umrahPackage="item" />
        </v-col>
      </template>
      <p v-else class="text-center mt-5 headline text-capitalize">Oops, Couldn't find packages !</p>
    </v-row>
    <v-row v-else justify="center" class="my-10">
      <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
      class="mx-auto"
      ></v-progress-circular>
    </v-row>
  </v-container> -->
  <packageDetails />
</template>

<script>
import packageDetails from '@/views/packages/packageDetails'
// import packages from '@/requests/packages.js'

export default {
  name: 'Home',

  components: {
    packageDetails
  },
  // data () {
  //   return {
  //     loading: true,
  //     alertExist: false,
  //     alertText: '',
  //     alertType: ''
  //   }
  // },
  // computed: {
  //   packagesList () {
  //     return this.$store.state.packages
  //   }
  // },
  created () {
    // packages.getPackages().then(res => {
    //   if (res.data.status === 200) {
    //     this.$store.dispatch('setPackages', res.data.data.packageList)
    //   }
    // })
    //   .catch(err => {
    //     this.alertExist = true
    //     this.alertText = err
    //     this.alertType = 'error'
    //   })
    //   .finally(() => {
    //     this.loading = false
    //   })
  }
}
</script>
