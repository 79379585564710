<template>
  <v-container>
    <v-snackbar
    :timeout="3000"
    v-model="alertExist"
    top
    :color="alertType"
    elevation="24"
    >
      {{alertText}}
    </v-snackbar>
    <div v-if="!loading">
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <template v-if="packageDetails">
        <!-- basic information -->
        <v-card outlined>
          <v-card-title class="primary">
            <h2 class="text-h5 white--text text-capitalize"><v-icon color="white" left large>mdi-swap-horizontal-bold</v-icon> Package overview</h2>
          </v-card-title>
          <v-card-text class="pa-2">
            <!-- includes and excludes -->
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Includes
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p v-html="packageDetails.includes"></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Excludes
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p v-html="packageDetails.excludes"></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>

        <!-- customization panel -->
        <v-row class="my-5">
          <v-col cols="12" md="8" class="hotel-sec">
            <!-- flight card -->
            <!-- <flightCard :flight="flight" @goToResults="redirectToFlightResults" /> -->

            <!-- hotels component -->
            <v-card outlined>
              <v-card-title class="primary">
                <h2 class="text-h5 white--text text-capitalize font-weight-medium"><v-icon large left color="white">mdi-office-building</v-icon>hoteld reservations</h2>
              </v-card-title>
              <v-card-text>
                <!-- get hotels component -->
                <hotels @clearFlight="$store.dispatch('removeFlight')" :cities="packageDetails.cities" />
              </v-card-text>
            </v-card>

            <!-- transportation component -->
            <!-- <v-card outlined class="my-5">
              <v-card-title class="primary">
                <h2 class="text-h5 white--text text-capitalize">package transportation</h2>
              </v-card-title>
              <v-card-text class="pa-3">
                <div class="d-flex align-center justify-space-between">
                  <div class="text-h6 headers--text text-capitalize">{{packageDetails.PackageTransportations[0].type}}</div>
                  <div class="text-h6 font-weight-black headers--text text-capitalize">${{packageDetails.PackageTransportations[0].price_per_person}}<small class="font-weight-regular">/person</small></div>
                </div>
              </v-card-text>
            </v-card> -->
          </v-col>
          <v-col cols="12" md="4">
            <package-controls @clearFlight="$store.dispatch('removeFlight')" />
            <!-- <v-btn class="py-5" block tile elevation="0" color="success" :disabled="!originAirport" @click="checkAvailabity">search for flights</v-btn> -->
            <!-- <small v-if="!originAirport">Add origin to search!</small> -->
          </v-col>
        </v-row>
        <div class="d-flex justify-space-between align-center price-sec">
          <div class="caption error--text">
            <span v-if="!originAirport || !homeAirport">Please add origin and destination airports to continue</span>
          </div>
          <div>
            <v-btn color="success" tile elevation="0" :disabled="!originAirport || !homeAirport" @click="redirectToFlights">Continue To Flights</v-btn>
            <br>
            <!-- <small v-if="!flight">Include a flight to continue!</small> -->
          </div>
        </div>
      </template>
      <p v-else class="text-center mt-5 headline text-capitalize">Oops, Couldn't get package details !</p>
    </div>
    <v-row v-else justify="center" class="mt-10">
      <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
      class="mx-auto"
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>
<script>
// web services
import packages from '@/requests/packages.js'
import flights from '@/requests/flights.js'

// components imports
import packageControls from '@/components/packageControls'
import hotels from '@/components/hotels'
// import flightCard from '@/components/flights/flightCard'

// state
import { mapState } from 'vuex'

export default {
  props: ['id'],
  data () {
    return {
      alertExist: false,
      alertText: '',
      alertType: '',
      loading: true,
      overlay: false,
      prevRoute: null
    }
  },
  components: {
    hotels,
    packageControls
    // flightCard
  },
  computed: {
    ...mapState(['originAirport', 'returnAirport', 'destinationAirport', 'homeAirport', 'classType', 'startDate', 'adults', 'children', 'oldChildren', 'infants', 'packageDetails', 'flight']),
    totalPrice () {
      let total = 0
      total += this.adults * Number(this.packageDetails.PackageTransportations[0].price_per_person)
      if (this.flight) total += Number(this.flight.pricingInfo.TotalFare)
      else total += 800

      this.packageDetails.cities.forEach(city => {
        if (city.cityHotels[0]) {
          let roomPrice
          switch (this.adults) {
            case 1:
              roomPrice = city.cityHotels.find(hotel => hotel.room.roomOccupancy === 1).room.price
              break
            case 2:
              roomPrice = city.cityHotels.find(hotel => hotel.room.roomOccupancy === 2).room.price
              break
            case 3:
              roomPrice = city.cityHotels.find(hotel => hotel.room.roomOccupancy === 3).room.price
              break
            case 4:
              roomPrice = city.cityHotels.find(hotel => hotel.room.roomOccupancy === 4).room.price
              break
            default:
              roomPrice = 0
              break
          }
          total += roomPrice * city.cityNightNumber
        }
      })
      return total.toFixed(2)
    }
  },
  methods: {
    checkAvailabity () {
      this.getFlight()
    },
    createFlightQuery () {
      const params = new URLSearchParams()
      let isMultiCityFlight = true
      if (this.originAirport.Code === this.homeAirport.Code && this.destinationAirport.Code === this.returnAirport.Code) isMultiCityFlight = false
      if (!isMultiCityFlight) {
        params.append('tripType', 'round')
        params.append('origin', this.originAirport.Code)
        params.append('destination', this.destinationAirport.Code)
        params.append('originType', this.originAirport.Type)
        params.append('destinationType', this.destinationAirport.Type)
        params.append('departureDate', this.startDate)
        params.append('arrivalDate', this.addNightsToStartDate('round'))
      } else {
        // depart flight
        params.append('tripType', 'multi')
        params.append('origin[]', this.originAirport.Code)
        params.append('originType[]', this.originAirport.Type)
        params.append('destination[]', this.destinationAirport.Code)
        params.append('destinationType[]', this.destinationAirport.Type)
        params.append('departureDate[]', this.startDate)
        // return flight
        params.append('origin[]', this.returnAirport.Code)
        params.append('originType[]', this.returnAirport.Type)
        params.append('destination[]', this.homeAirport.Code)
        params.append('destinationType[]', this.homeAirport.Type)
        params.append('departureDate[]', this.addNightsToStartDate('multi'))
      }
      params.append('classType', this.classType.value)
      params.append('adults', this.adults)
      params.append('children', this.children + this.oldChildren)
      params.append('infants', this.infants)
      return params
    },
    getFlight () {
      if (this.originAirport) {
        this.overlay = true

        const params = this.createFlightQuery()

        flights.getFlights(params).then(res => {
          if (res.data.status) {
            this.$store.dispatch('setFlight', res.data.data)
            this.$vuetify.goTo(100, { duration: 300, easing: 'easeInOutCubic' })
          } else {
            this.alertExist = true
            this.alertText = res.data.message
            this.alertType = 'error'
          }
        })
          .finally(() => {
            this.loading = false
            this.overlay = false
          })
      } else {
        this.alertExist = true
        this.alertText = 'Please add an origin'
        this.alertType = 'error'
      }
    },
    addNightsToStartDate (tripType) {
      const startDate = new Date(this.startDate)
      let finalDate = null
      let nights = 0
      // if (type === 'round') {
      this.packageDetails.cities.forEach(city => {
        nights += Number(city.cityNightNumber)
      })
      if (tripType === 'round') finalDate = startDate.setDate(startDate.getDate() + nights + 1)
      else finalDate = startDate.setDate(startDate.getDate() + nights)
      // } else {
      //   nights += Number(this.packageDetails.cities[0].cityNightNumber)
      //   finalDate = startDate.setDate(startDate.getDate() + nights + 1)
      // }
      return new Date(finalDate).toISOString().substr(0, 10)
    },
    redirectToFlights () {
      this.$store.dispatch('setPackagePrice', this.totalPrice)
      this.$router.push({ name: 'flightResults', query: { flightQuery: this.createFlightQuery().toString() } })
    }
    // redirectToBooking () {
    //   this.$store.dispatch('setPackagePrice', this.totalPrice)
    //   this.$router.push({ name: 'packageBook', params: { id: this.id } })
    // }
  },
  created () {
    if (!this.packageDetails) {
      packages.getPackage(1).then(res => {
        if (res.status === 200) {
          this.$store.dispatch('setPackage', res.data[0])
        }
      })
        .catch(err => {
          this.alertExist = true
          this.alertText = err
          this.alertType = 'error'
        })
        .finally(() => {
          this.loading = false
        })
    } else this.loading = false
  }
}
</script>

<style>
 .hotel-sec {
   position: sticky;
   top: 0;
 }
.price-sec {
  padding: 10px;
  background-color: white;
  border-top: solid 0.5px #E0E0E0;
  position: sticky;
  bottom: 0;
}
</style>
