var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-5",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"primary white--text text-capitalize"},[_c('v-icon',{attrs:{"large":"","left":"","color":"white"}},[_vm._v("mdi-airplane")]),_vm._v(" Add Flight ")],1),_c('v-card-text',{staticClass:"pa-3"},[_c('h2',{staticClass:"primary--text mb-3"},[_c('v-icon',{attrs:{"large":"","left":"","color":"primary"}},[_vm._v("mdi-airplane-takeoff")]),_vm._v("Depart trip")],1),_c('autocomplete',{attrs:{"tripType":"from"}}),_c('v-select',{attrs:{"prepend-inner-icon":"mdi-airplane-landing","items":_vm.saudiAirports,"item-text":"Name","item-value":"Code","color":"primary","return-object":"","outlined":"","item-color":"blue","label":"To"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blueDark--text"},[_vm._v(" "+_vm._s(item.Name + ', ' + item.Code)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blueDark--text"},[_vm._v(" "+_vm._s(item.Name+ ', ' + item.Code)+" ")])]}}]),model:{value:(_vm.destination),callback:function ($$v) {_vm.destination=$$v},expression:"destination"}}),_c('v-divider'),_c('h2',{staticClass:"primary--text my-3"},[_c('v-icon',{attrs:{"large":"","left":"","color":"primary"}},[_vm._v("mdi-airplane-landing")]),_vm._v("Return trip")],1),_c('v-select',{attrs:{"prepend-inner-icon":"mdi-airplane-takeoff","items":_vm.saudiAirports,"item-text":"Name","item-value":"Code","color":"primary","return-object":"","outlined":"","item-color":"blue","label":"From"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blueDark--text"},[_vm._v(" "+_vm._s(item.Name + ', ' + item.Code)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blueDark--text"},[_vm._v(" "+_vm._s(item.Name+ ', ' + item.Code)+" ")])]}}]),model:{value:(_vm.returnAirport),callback:function ($$v) {_vm.returnAirport=$$v},expression:"returnAirport"}}),_c('autocomplete',{attrs:{"tripType":"to"}}),_c('v-divider'),_c('h2',{staticClass:"primary--text my-3"},[_c('v-icon',{attrs:{"large":"","left":"","color":"primary"}},[_vm._v("mdi-chevron-double-up")]),_vm._v("Trip Class")],1),_c('v-select',{attrs:{"items":_vm.classes,"color":"primary","return-object":"","outlined":"","item-color":"primary"},on:{"change":_vm.setClass},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blueDark--text"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.classType),callback:function ($$v) {_vm.classType=$$v},expression:"classType"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }