<template>
    <div>
      <v-app-bar
      light
      color="white"
      elevation="3"
      :hide-on-scroll="false"
      >
        <v-container>
          <v-row align="center" justify="space-between">
              <v-img
              alt="Adam Logo"
              class="shrink mr-2"
              contain
              src="../assets/logo-adam.png"
              transition="scale-transition"
              width="150"
              />

              <div class="d-none d-sm-flex">
                  <v-btn color="primary" @click="goTo('offices')" height="53" text><span class="body-2 font-weight-bold">Offices</span></v-btn>
                  <v-btn color="primary" @click="goTo('about-adam')" height="53" text><span class="body-2 font-weight-bold">About us</span></v-btn>
                  <v-btn color="primary" @click="goTo('contact-adam')" height="53" text><span class="body-2 font-weight-bold">Contact</span></v-btn>
              </div>
              <v-btn icon @click="drawer = !drawer" class="d-flex d-sm-none">
                  <v-icon large color="primary">mdi-menu</v-icon>
              </v-btn>
          </v-row>
        </v-container>
      </v-app-bar>
      <v-navigation-drawer
      v-model="drawer"
      absolute
      color="white"
      dark
      temporary
      >
          <v-list nav dense>
              <v-list-item>
                  <v-img src="../assets/logo-adam.png" alt="adam logo" max-width="120px"></v-img>
                  <v-divider></v-divider>
              </v-list-item>

              <v-list-item-group
              color="primary"
              >

                  <v-list-item @click="goTo('offices')">
                      <v-list-item-content>
                          <v-list-item-title class="text--primary">Offices</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="goTo('about-adam')">
                      <v-list-item-content>
                          <v-list-item-title class="text--primary">About Us</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="goTo('contact-adam')">
                      <v-list-item-content>
                          <v-list-item-title class="text--primary">Contact</v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
          </v-list>
      </v-navigation-drawer>
    </div>
</template>

<script>
export default {
  data () {
    return {
      drawer: false
    }
  },
  methods: {
    goTo (link) {
      let baseUrl
      switch (link) {
        case 'about-adam':
          baseUrl = 'https://adamtravel.com/about-adam-travel/'
          window.open(baseUrl, '_blank')
          break
        case 'contact-adam':
          baseUrl = 'https://adamtravel.com/contact/'
          window.open(baseUrl, '_blank')
          break
        case 'offices':
          baseUrl = 'https://adamtravel.com/offices/'
          window.open(baseUrl, '_blank')
          break
        default:
          this.$router.push({ name: link })
          break
      }
    }
  }
}
</script>
