<template>
    <v-autocomplete
    :id="`autocomplete-input-${tripType}`"
    v-model="select"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    :prepend-inner-icon="tripType === 'from' ? 'mdi-airplane-takeoff' : 'mdi-airplane-landing'"
    hide-no-data
    outlined
    clearable
    no-filter
    :menu-props="{ auto: false, maxWidth: 'auto', overflowY: false }"
    :allow-overflow="false"
    item-text="Name"
    item-value="Code"
    color="primary"
    height="52"
    single-line
    return-object
    :label="tripType"
    :placeholder="tripType"
    :rules="[v => !!v || 'Item is required']"
    >
        <template :id="`list-item-${data.item.code}`" v-slot:item="data">
            <v-list-item-content >
                <v-list-item-title>{{data.item.Name}}, {{data.item.City}}</v-list-item-title>
                <v-list-item-subtitle>{{data.item.Country}},{{ data.item.Code}}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
        <template v-slot:selection="data">
            <span class="ac-selection">{{data.item.Name}},{{data.item.Code}}</span>
        </template>
    </v-autocomplete>
</template>

<script>
import flights from '@/requests/flights.js'
import { mapState } from 'vuex'

export default {
  props: ['tripType'],
  data () {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      initialSearch: true
    }
  },
  computed: {
    ...mapState(['homeAirport'])
  },
  watch: {
    search (val) {
      val && val !== this.select && this.querySelections(val)
    },
    select (value) {
      if (this.tripType === 'from') {
        this.$store.dispatch('setOriginAirport', value)
        if (!this.homeAirport) this.$store.dispatch('setHomeAirport', value)
      } else this.$store.dispatch('setHomeAirport', value)
    },
    homeAirport (newVal, oldVal) {
      if (this.tripType === 'to' && newVal) this.querySelections(newVal.Code)
    }
  },
  methods: {
    querySelections (v) {
      if (!v) return null
      this.loading = true
      const params = new URLSearchParams()
      params.append('term', v)
      flights.getAirports(params).then(res => {
        if (Array.isArray(res.data)) {
          this.items = res.data
          if (this.initialSearch) {
            // if (this.items.length === 1) {
            //   this.select = this.items[0]
            //   this.initialSearch = false
            // } else if (selectFirst) this.select = this.items[0]
            if (this.tripType === 'from') {
              if (this.$store.state.originAirport) this.select = this.items.find(item => item.Code === this.$store.state.originAirport.Code)
            } else {
              console.log('hereeee', this.$store.state.homeAirport, this.$store.state.originAirport)
              if (this.$store.state.homeAirport) this.select = this.items.find(item => item.Code === this.$store.state.homeAirport.Code)
            //   else if (this.$store.state.originAirport) this.select = this.$store.state.originAirport
            }
            // if (this.$store.state.homeAirport) this.select = this.items.find(item => item.Code === this.$store.state.homeAirport.Code)
            this.initialSearch = false
          }
        } else {
          this.loading = false
          this.items = []
        }
      })
        .catch(() => {
          // this.alertExist = true
          // this.alertText = err
          // this.alertType = 'error'
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    if (this.tripType === 'from') {
      if (this.$store.state.originAirport) this.search = this.$store.state.originAirport.Code
    } else {
      if (this.$store.state.homeAirport) this.search = this.$store.state.homeAirport.Code
    //   else if (this.$store.state.originAirport) this.search = this.$store.state.originAirport.Code
    }
  }
}
</script>
