<template>
    <div>
        <!-- start date picker -->
        <v-card outlined class="mb-5">
            <v-card-title class="primary">
                <h2 class="text-h5 mb-0 white--text text-capitalize font-weight-bold"><v-icon large left color="white">mdi-calendar-month</v-icon>Departure Date</h2>
            </v-card-title>
            <v-card-text class="pa-3 pt-10">
                <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="startDateText"
                    label="Picker without buttons"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    color="blue"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="tempStartDate"
                    :min="minDate"
                    @input="menu = false"
                    ></v-date-picker>
                </v-menu>
            </v-card-text>
        </v-card>

        <!-- flight -->
        <flights-search />

        <!-- occupancy -->
        <v-card outlined class="mb-5">
            <v-card-title class="primary">
                <h2 class="text-h5 white--text text-capitalize">Occupancy</h2>
            </v-card-title>
            <v-card-text class="pa-3">
                <div class="d-flex align-center justify-space-between my-2">
                    <span class="body-1">Adults</span>
                    <div class="d-flex align-center">
                        <v-btn :disabled="adults + oldChildren >= 4" small elevation="0" tile color="grey lighten-2" class="pa-5" @click="changePassengersNumbers('adults', 'inc')">+</v-btn>
                        <div class="grey lighten-3 pa-2 body-1">{{adults}}</div>
                        <v-btn :disabled="adults <= 1" small elevation="0" tile color="grey lighten-2" class="pa-5" @click="changePassengersNumbers('adults', 'dec')">-</v-btn>
                    </div>
                </div>
                <small>Adults older than 12 years</small>

                <div class="d-flex align-center justify-space-between my-2">
                    <span class="body-1">Children</span>
                    <div class="d-flex align-center">
                        <v-btn :disabled="oldChildren + adults >= 4" small elevation="0" tile color="grey lighten-2" class="pa-5" @click="changePassengersNumbers('oldChildren', 'inc')">+</v-btn>
                        <div class="grey lighten-3 pa-2 body-1">{{oldChildren}}</div>
                        <v-btn :disabled="oldChildren < 1" small elevation="0" tile color="grey lighten-2" class="pa-5" @click="changePassengersNumbers('oldChildren', 'dec')">-</v-btn>
                    </div>
                </div>
                <small>Children from 6 to 12 years</small>

                <div class="d-flex align-center justify-space-between my-2">
                    <span class="body-1">Children </span>
                    <div class="d-flex align-center">
                        <v-btn :disabled="children >= 4" small elevation="0" tile color="grey lighten-2" class="pa-5" @click="changePassengersNumbers('children', 'inc')">+</v-btn>
                        <div class="grey lighten-3 pa-2 body-1">{{children}}</div>
                        <v-btn :disabled="children < 1" small elevation="0" tile color="grey lighten-2" class="pa-5" @click="changePassengersNumbers('children', 'dec')">-</v-btn>
                    </div>
                </div>
                <small>Children from 2 to 6 years</small>

                <div class="d-flex align-center justify-space-between my-2">
                    <span class="body-1">Infants</span>
                    <div class="d-flex align-center">
                        <v-btn :disabled="infants >= adults" small elevation="0" tile color="grey lighten-2" class="pa-5" @click="changePassengersNumbers('infants', 'inc')">+</v-btn>
                        <div class="grey lighten-3 pa-2 body-1">{{infants}}</div>
                        <v-btn :disabled="infants < 1" small elevation="0" tile color="grey lighten-2" class="pa-5" @click="changePassengersNumbers('infants', 'dec')">-</v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import flightsSearch from '@/components/flights/flightsSearch'

export default {
  components: {
    flightsSearch
  },
  data () {
    return {
      menu: false,
      tempStartDate: null
    }
  },
  watch: {
    tempStartDate (val) {
      this.$emit('clearFlight')
      this.$store.dispatch('updateStartDate', val)
    }
  },
  computed: {
    ...mapState(['adults', 'children', 'oldChildren', 'infants', 'startDate']),
    startDateText () {
      return new Date(this.startDate).toString().substr(0, 15)
    },
    minDate () {
      const minDate = new Date()
      minDate.setDate(minDate.getDate() + 1)
      return minDate.toISOString().substr(0, 10)
    }
  },
  methods: {
    changePassengersNumbers (passengerType, method) {
      this.$emit('clearFlight')
      this.$store.dispatch('changePassengersNumbers', { type: passengerType, action: method })
    }
  },
  created () {
    if (this.startDate) this.tempStartDate = this.startDate
    else this.tempStartDate = this.minDate
  }
}
</script>
