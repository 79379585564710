// /airport/autocomplete?term=${term}
import Vue from 'vue'
import { flightsApi } from '../APILinks'

const timeout = 30000
export default {
  // get airportd
  async getAirports (query) {
    try {
      return await Vue.axios.get(flightsApi + 'airport/autocomplete',
        {
          timeout: timeout,
          headers: { Accept: 'application/json', 'Content-type': 'application/json' },
          params: query
        })
    } catch (err) {
      console.log('error', err)
    }
  },

  // get cheapest flights
  async getFlights (query) {
    try {
      return await Vue.axios.get(flightsApi + 'flights/lowestPrice',
        {
          timeout: timeout,
          headers: { Accept: 'application/json', 'Content-type': 'application/json' },
          params: query,
          data: null
        })
    } catch (err) {
      console.log('error', err)
    }
  },

  // get flights
  async getAllFlights (query) {
    try {
      return await Vue.axios.post(flightsApi + 'flights/search?' + query,
        {},
        {
          timeout: timeout,
          headers: { Accept: 'application/json', 'Content-type': 'application/json' },
          data: null
        })
    } catch (err) {
      console.log('error', err)
    }
  },

  // get flights
  async getFlight (query) {
    try {
      return await Vue.axios.post(flightsApi + 'flights?' + query,
        {},
        {
          timeout: timeout,
          headers: { Accept: 'application/json', 'Content-type': 'application/json' },
          data: null
        })
    } catch (err) {
      console.log('error', err)
    }
  },

  // create pnr
  async createPNR (body) {
    try {
      return await Vue.axios.post(flightsApi + 'pnr/create',
        body,
        {
          timeout: 30000,
          headers: { Accept: 'application/json', 'Content-type': 'application/json' }
        })
    } catch (err) {
      console.log('error', err)
    }
  }
}
