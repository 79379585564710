<template>
    <div>
        <v-card outlined class="mb-5">
            <v-card-title class="primary white--text text-capitalize">
              <v-icon large left color="white">mdi-airplane</v-icon>
              Add Flight
            </v-card-title>
            <v-card-text class="pa-3">
              <h2 class="primary--text mb-3"><v-icon large left color="primary">mdi-airplane-takeoff</v-icon>Depart trip</h2>
                <autocomplete tripType="from" />

                <!-- destination airport -->
                <v-select
                prepend-inner-icon="mdi-airplane-landing"
                :items="saudiAirports"
                v-model="destination"
                item-text="Name"
                item-value="Code"
                color="primary"
                return-object
                outlined
                item-color="blue"
                label="To"
                >
                  <template v-slot:item="{ item }">
                    <span class="blueDark--text">
                      {{item.Name + ', ' + item.Code}}
                    </span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="blueDark--text">
                      {{item.Name+ ', ' + item.Code}}
                    </span>
                  </template>
                </v-select>

              <v-divider></v-divider>
              <h2 class="primary--text my-3"><v-icon large left color="primary">mdi-airplane-landing</v-icon>Return trip</h2>
                <!-- return airport -->
                <v-select
                prepend-inner-icon="mdi-airplane-takeoff"
                :items="saudiAirports"
                v-model="returnAirport"
                item-text="Name"
                item-value="Code"
                color="primary"
                return-object
                outlined
                item-color="blue"
                label="From"
                >
                  <template v-slot:item="{ item }">
                    <span class="blueDark--text">
                      {{item.Name + ', ' + item.Code}}
                    </span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="blueDark--text">
                      {{item.Name+ ', ' + item.Code}}
                    </span>
                  </template>
                </v-select>

                <autocomplete tripType="to" />

                <v-divider></v-divider>

                <!-- flight class -->
                <h2 class="primary--text my-3"><v-icon large left color="primary">mdi-chevron-double-up</v-icon>Trip Class</h2>
                <v-select
                :items="classes"
                v-model="classType"
                color="primary"
                return-object
                outlined
                item-color="primary"
                @change="setClass"
                >
                  <template v-slot:selection="{ item }">
                    <span class="blueDark--text">
                      {{item.text}}
                    </span>
                  </template>
                </v-select>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import autocomplete from '@/components/flights/autocomplete'

export default {
  components: { autocomplete },
  data () {
    return {
      classType: { text: 'Economy', value: 'Y' },
      classes: [
        { text: 'Economy', value: 'Y' },
        { text: 'Premium', value: 'S' },
        { text: 'Business', value: 'C' },
        { text: 'First', value: 'F' }
      ],
      saudiAirports: [
        {
          Code: 'JED',
          Name: 'King Abdulaziz Int',
          City: 'Jeddah',
          countryCode: 'SA',
          Country: 'Saudi Arabia',
          Type: 'A'
        },
        {
          Code: 'MED',
          Name: 'Madinah',
          City: 'Madinah',
          countryCode: 'SA',
          Country: 'Saudi Arabia',
          Type: 'A'
        }
      ],
      returnAirport: null,
      destination: null
    }
  },
  watch: {
    returnAirport (value) {
      this.$store.dispatch('setReturnAirport', value)
    },
    destination (value) {
      this.$store.dispatch('setDestinationAirport', value)
    }
  },
  methods: {
    setClass () {
      this.$store.dispatch('setPassengersClass', this.classType)
    }
  },
  created () {
    if (this.$store.state.originAirport) this.search = this.$store.state.originAirport.Code
    if (this.$store.state.destinationAirport) this.destination = this.$store.state.destinationAirport
    else this.destination = this.$store.state.packageDetails.packageStartCity[0]
    if (this.$store.state.returnAirport) this.returnAirport = this.$store.state.returnAirport
    else this.returnAirport = this.$store.state.packageDetails.packageStartCity[0]
  }
}
</script>
